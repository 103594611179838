'use client';

import { Box } from '@mui/material';
import { FlexBox } from '@web/components/flex-box';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import NextImage from 'next/image';
import dynamic from 'next/dynamic';
import { usePathname } from 'next/navigation';
import { useDropdownContext } from '@web/context/dropdown';
import DumbLink from '@web/components/DumbLink';

const SearchInput = dynamic(
  () => import('@web/components/layouts/SearchInput')
);

const SearchBar = ({ isMobile, isFixed }: any) => {
  const pathname = usePathname();
  const { dropdownOpen, setDropdownOpen } = useDropdownContext();

  const handleNavigateNextClick = () => {
    setDropdownOpen(false);
  };
  return (
    <FlexBox alignItems="center" sx={{ width: '100%' }}>
      {dropdownOpen && isMobile ? (
        <FlexBox ml={1} alignItems="center" sx={{ color: 'black' }}>
          <ArrowBackIosIcon onClick={handleNavigateNextClick} />
        </FlexBox>
      ) : (
        <DumbLink href="/" passHref>
          <FlexBox alignItems="center">
            <NextImage
              src="/assets/logo.webp"
              alt="Buy wisely"
              width={40}
              height={40}
            />
          </FlexBox>
        </DumbLink>
      )}
      {(!isMobile || (pathname === '/' && !dropdownOpen && !isFixed)) && (
        <DumbLink href="/" passHref>
          <FlexBox alignItems="center">
            <NextImage
              priority
              src="/assets/text.png"
              alt="Buy wisely"
              width={150}
              height={27}
            />
          </FlexBox>
        </DumbLink>
      )}
      <Box width={isMobile ? '100%' : '500px'} mx={1}>
        {pathname !== '/' && <SearchInput isMobile={isMobile} />}
        {pathname === '/' && (dropdownOpen || isFixed) && isMobile && (
          <SearchInput isMobile={isMobile} />
        )}
        {pathname === '/' && isFixed && !isMobile && <SearchInput />}
      </Box>
    </FlexBox>
  );
};

export default SearchBar;
