'use client';

import {
  AppBar,
  Avatar,
  Container,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
} from '@mui/material';
import { useUser } from '@auth0/nextjs-auth0/client';
import { FlexBox } from '@web/components/flex-box';
import { useState } from 'react';
import { usePathname } from 'next/navigation';
import MenuMobile from '@web/components/layouts/MenuMobile';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import HistoryIcon from '@mui/icons-material/History';
import LoginIcon from '@mui/icons-material/Login';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import DumbLink from '@web/components/DumbLink';
import SearchBar from '@web/components/layouts/SearchBar';

const TopNav = () => {
  const pathname = usePathname();
  const { user, isLoading } = useUser();
  const [anchorElUser, setAnchorElUser] = useState(null);
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  return (
    <AppBar
      sx={{
        backdropFilter: 'blur(6px)',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
      }}
    >
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            display: { xs: 'flex', md: 'none' },
            height: 64,
          }}
        >
          <SearchBar isMobile />
          <MenuMobile />
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            display: { xs: 'none', md: 'flex' },
            height: 64,
          }}
        >
          <SearchBar />
          {!user && !isLoading && (
            <DumbLink href={`/api/auth/login?returnTo=${pathname}`}>
              <FlexBox alignItems="center" gap={1} color="primary.main">
                <LoginIcon />
                Login
              </FlexBox>
            </DumbLink>
          )}
          {user && !isLoading && (
            <FlexBox alignItems="center" gap={1} sx={{ flexGrow: 0 }}>
              <DumbLink href={`/my/collections`}>
                <FlexBox alignItems="center" color="grey.600">
                  <FavoriteBorderIcon fontSize="large" />
                </FlexBox>
              </DumbLink>
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt={user.name} src={user.picture} />
              </IconButton>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <FlexBox
                  alignItems="center"
                  justifyContent="center"
                  color="grey.600"
                >
                  {user?.nickname || user?.name}
                </FlexBox>
                <Divider />
                <MenuItem onClick={handleCloseUserMenu}>
                  <ListItemIcon>
                    <FavoriteBorderIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>
                    <DumbLink href={`/my/collections`}>My Collections</DumbLink>
                  </ListItemText>
                </MenuItem>
                <MenuItem onClick={handleCloseUserMenu}>
                  <ListItemIcon>
                    <HistoryIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>
                    <DumbLink href={`/my/history`}>Browsing History</DumbLink>
                  </ListItemText>
                </MenuItem>
                <MenuItem onClick={handleCloseUserMenu}>
                  <ListItemIcon>
                    <NotificationsNoneIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>
                    <DumbLink href={`/my/alerts`}>Price Alerts</DumbLink>
                  </ListItemText>
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleCloseUserMenu}>
                  <ListItemIcon>
                    <ExitToAppIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>
                    <DumbLink href={`/api/auth/logout?returnTo=${pathname}`}>
                      Logout
                    </DumbLink>
                  </ListItemText>
                </MenuItem>
              </Menu>
            </FlexBox>
          )}
        </Stack>
      </Container>
    </AppBar>
  );
};

export default TopNav;
