'use client';

import { Fragment, useState } from 'react';
import {
  IconButton,
  Drawer,
  Box,
  List,
  ListSubheader,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Divider,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import LogoutIcon from '@mui/icons-material/Logout';
import { usePathname } from 'next/navigation';
import HistoryIcon from '@mui/icons-material/History';
import LoginIcon from '@mui/icons-material/Login';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import DumbLink from '@web/components/DumbLink';

const MenuMobile = ({ user }) => {
  const pathname = usePathname();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  return (
    <Fragment>
      <IconButton
        edge="end"
        color="black"
        aria-label="menu"
        onClick={handleDrawerToggle}
      >
        <MenuIcon />
      </IconButton>
      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={handleDrawerToggle}
        PaperProps={{ style: { width: '100%', height: '100%' } }}
      >
        <List
          subheader={
            <ListSubheader>
              <Box display="flex" justifyContent="flex-end" alignItems="center">
                <IconButton aria-label="close" onClick={handleDrawerToggle}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </ListSubheader>
          }
        >
          <ListItemButton>
            <ListItemText primary={user?.nickname || user?.name} />
          </ListItemButton>
          {user && (
            <>
              <ListItemButton href={`/my/collections`}>
                <ListItemIcon>
                  <FavoriteBorderIcon />
                </ListItemIcon>
                <ListItemText primary="My Collections" />
              </ListItemButton>
              <ListItemButton href={`/my/alerts`}>
                <ListItemIcon>
                  <NotificationsNoneIcon />
                </ListItemIcon>
                <ListItemText primary="Price Alerts" />
              </ListItemButton>
            </>
          )}
          <ListItemButton href={`/my/history`}>
            <ListItemIcon>
              <HistoryIcon />
            </ListItemIcon>
            <ListItemText primary="Browsing History" />
          </ListItemButton>
          <Divider />
          {user ? (
            <DumbLink href={`/api/auth/logout?returnTo=${pathname}`} passHref>
              <ListItemButton>
                <ListItemIcon>
                  <LogoutIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="Log out" sx={{ color: '#D23F57' }} />
              </ListItemButton>
            </DumbLink>
          ) : (
            <DumbLink
              href={`/api/auth/login?returnTo=${pathname}`}
              passHref
              color="primary"
            >
              <ListItemButton>
                <ListItemIcon>
                  <LoginIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="Sign in" sx={{ color: '#D23F57' }} />
              </ListItemButton>
            </DumbLink>
          )}
        </List>
      </Drawer>
    </Fragment>
  );
};

export default MenuMobile;
